@import "../../styles/variables";

.wrapper {
	background-color: white;
	height: $header-height;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 1.5rem;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	border-bottom: 1px solid $color-gray-border;

	.logo-autoscal {
		width: 150px;
	}

	.right-menu {
		display: flex;
		align-items: center;
		gap: 24px;

		.bell-icon {
			position: relative;
		}

		.bell-circle {
			position: absolute;
			height: 5.5px;
			width: 5.5px;
			right: 1px;
			top: 2px;
			border-radius: 50%;
			background-color: $color-text-secondary;
		}

		.profile-icon {
			height: 36px;
			width: 36px;
			font-size: 16px;
		}

		.time {
			border: 1px solid $color-gray-border;
			border-right: none;
			border-radius: 8px 0 0 8px;
			padding: 0px 12px;
			font-size: 14px;
			font-weight: 500;
			line-height: 1.5;
		}
	}
}
