.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  .heading {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: center;
  }

  .subHeading {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
  }

}