@import "../../../styles/variables";

.wrapper{
  padding:12px;
  display: flex;
  flex-direction: column;
  gap:24px;
  .task{
    display: flex;
    align-items: center;
    gap:12px;
    .avatar-wrapper{
      .avatar{
        height: 24px;
        width: 24px;
        color : $color-btn-text;

        &.skipped{
          background-color : #2F80ED !important;
        }

        &.completed{
          background-color : #27AE60 !important;
          color:#ffffff !important;
        }

        &.active{
          background-color : #F7CF51 !important;
        }
      }
    }
    .task-title{
      font-weight:500;
      color : $color-btn-text;

      &.active{
        font-weight: 700;
      }
    }
  }
}