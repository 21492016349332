@import "../../styles/variables.scss";

.info-wrapper {
	overflow-wrap: break-word;
	display: flex;
	position: relative;
	flex-direction: column;
	gap: 0.5rem;

	.heading {
		color: $color-info-heading;
		font-size: 14px;
		line-height: 16px;
		font-weight: 700;
		max-width: 100%;

		&.small {
			margin-bottom: 4px;
		}

		&.medium {
			margin-bottom: 10px;
		}
	}

	.value {
		font-weight: 500;
		color: $color-info-value;
		line-height: 20px;
		width: max-content;
		max-width: 100%;

		&.small {
			font-size: 14px;
		}

		&.medium {
			font-size: 16px;
		}
	}

	&.highlighted {
		padding: 0px 16px;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: var(--highlightedWidth);
		background-color: var(--highlightedColor);
		height: 100%;
		border-radius: 8px 8px;
		z-index: 1;
	}
}
