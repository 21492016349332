@import "../../../styles/variables";

.wrapper {
  .input {
    // border: 1px solid rgba(0, 0, 0, 0.2);
    border: 1px solid $color-input-border-default;
    outline: none;
    // height: 38px;
    height: 40px;
    box-shadow: none;
    border-radius: 4px;
    width: 100%;

    &:focus {
      // box-shadow: none;
      border: 1px solid $color-input-border-focused;
      box-shadow: 0px 0px 0px 4px rgba(255, 250, 234, 1),
        0px 1px 2px rgba(16, 24, 40, 0.05);
    }

    &:global {
      &.ant-input-status-error:not(.ant-input-disabled)   {
        box-shadow: none !important;
        border-color: $color-input-border-error !important;
      }
    }
  }
  .dropdown-icon {
    width: 10px;
    opacity: 0.6;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
