.leaderboard-card-wrapper {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #ebebeb;
	padding: 12px 16px;
	min-height: 90px;
	margin-bottom: 10px;
	margin-top: 10px;

	&:hover {
		border: 1px solid #ebebeb;
		border-radius: 10px;
		box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.05);
		border-bottom: none;
	}

	.squad-batch {
		width: 45px;
		display: flex;
		justify-content: center;
	}

	.squad-name {
		display: flex;
		align-items: center;
		gap: 20px;

		.squad-title {
			font-size: 16px;
			font-weight: 500;
			color: #262626;
		}
	}

	.squad-members-points {
		display: flex;
		align-items: center;
		gap: 20px;

		.points-title {
			font-size: 16px;
			font-weight: 700;
			color: #262626;
		}
	}
}

.drawer-leaderboard-wrapper {
	.banner {
		display: flex;
		align-items: center;
		gap: 15px;

		.name {
			font-weight: 600;
			font-size: 24px;
			line-height: 30px;
			background-image: linear-gradient(
				to right,
				rgba(123, 79, 250, 1),
				rgba(230, 69, 121, 1),
				rgba(244, 170, 65, 1)
			);
			-webkit-background-clip: text;
			color: transparent;
		}

		.sub-title {
			margin-top: 4px;
			color: rgba(89, 89, 89, 1);
			font-size: 13px;
			font-weight: 500;
		}
	}

	.header {
		background: rgba(255, 254, 240, 1);

		&::after {
			border-top: none !important;
		}
	}

	.team-member-squad {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	:global {
		.ant-table-cell {
			border-bottom: none !important;
		}

		thead tr {
			display: none;
		}
	}
}
