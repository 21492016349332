@import "../../styles/variables";

.wrapper {
  display: flex;
  min-height: calc(100vh - 96px);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 100%;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 32px;
    }

    .link {
      font-style: normal;
      font-weight: 600;
      text-align: center;
      font-size: 13px;
      line-height: 16px;
      color: rgba(50, 50, 50, 0.7);
      padding: 9px 20px;
      border: 2px solid $color-btn-primary;
      border-radius: 16px;
      width: 235px;
      height: 40px;
    }
  }
}
