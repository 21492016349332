@import "../../../styles/variables";

.wrapper {
  width: 100%;

  :global {
    .ant-input {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      &:focus {
        // box-shadow: none;
        border: 1px solid $color-input-border-focused;
        box-shadow: 0px 0px 0px 4px rgba(255, 250, 234, 1),
          0px 1px 2px rgba(16, 24, 40, 0.05);
      }
    }

    .ant-input-status-error {
      box-shadow: none !important;
      border-color: $color-input-border-error !important;
    }
  }
}