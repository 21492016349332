@import "../../styles/variables.scss";

.drawer {
  :global {
    .ant-drawer-close {
      display: none;
    }

    .ant-drawer-header {
      padding: 0;
      border-bottom: none;
    }

    .ant-drawer-body {
      padding: 24px 20px;
    }
  }

  .header {
    position: relative;
    padding: 24px 20px;

    .title-cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .title {
        color: $color-btn-text;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $color-text-praise-title;
      }

      .extra-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        svg {
          cursor: pointer;
          color: $color-text-praise-title;
        }
      }

    }

    &::after {
      position: absolute;
      content: "";
      width: calc(100% - 40px);
      bottom: 0;
      border-top: 1px solid $color-border-btn-secondary;
    }
  }
}