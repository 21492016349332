.wrapper {
	padding: 24px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	max-width: 100%;
	max-height: 100%;
	height: 100%;
	overflow-y: scroll;
	position: relative;

	.btn-wrapper {
		width: 170px;
		display: flex;
		justify-content: end;
		position: absolute;
		top: 1rem;
		right: 1rem;
	}
}
