@import "../../styles/variables";

.wrapper {
  display: flex;
  min-height: calc(100vh - 96px);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    .img {
      width: 100%;
      max-width: 600px;
      margin-bottom: 46px;
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 100%;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 32px;
    }
  }
}