@import "../../styles/variables";

.form-container {
	.title {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 21px;
		color: rgba(0, 0, 0, 0.8);
		text-align: center;
		margin-bottom: 20px;
	}

	:global {
		.ant-form-item-required {
			color: $color-text-lighter;
		}
	}

	.name-inputs {
		display: flex;
		justify-content: space-between;
		gap: 16px;
	}

	.form-submit-btn {
		width: 100%;
		margin-bottom: 20px;
	}

	.have-an-account {
		text-align: center;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		color: rgba(0, 0, 0, 0.6);

		.back-to-login {
			font-weight: 600;
			color: #ca9d0e;
		}
	}
}
