@import "../../styles/variables";

.password-validation {
	.validation,
	.validated {
		display: flex;
		gap: 8px;
		align-items: center;

		.dot {
			width: 8px;
			height: 8px;
			background-color: #d9d9d9;
			border-radius: 50px;
		}

		.validation-name {
			color: white;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 21px;
		}
	}
}
