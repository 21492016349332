@import "../../../styles/variables";

.inner-wrapper {
	display: flex;
	max-width: 1050px;
	margin: auto;
	padding: 65px 20px;
	gap: 80px;
	align-items: center;

	.left-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 12px 0 0 12px;
		width: 50%;
		position: relative;
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;

		.left-inner-content {
			.left-content-title {
				color: #262626;
				font-style: normal;
				font-weight: 600;
				font-size: 35px;
				margin: 32px 0px 15px 0px;
				text-align: center;
			}

			.left-content-list-wrapper {
				list-style-type: none;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 24px;
				margin-left: 15px;
				text-align: center;

				.left-content-list-item {
					color: #434343;
					padding-bottom: 20px;
				}
			}
		}
	}

	.right-content {
		background-color: #fff;
		width: 40%;
		border-radius: 10px;
		padding: 35px 24px;
		box-shadow: 1px 5px 9px #8e9b9f1a;
	}
}

.inner-wrapper-solo {
	max-width: 500px;
	.right-content {
		width: 100%;
		border-radius: 12px;
	}
}
