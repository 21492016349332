$color-brand-dark:                       #1E2934;

$color-btn:                              #E5E5E5;
$color-btn-primary:                      #F7CF51;
$color-btn-danger:                       #C73737;
$color-tag:                              #3881EF;

$color-gray-border:                      #D9D9D9;
$color-btn-text:                         #262626;
$color-btn-background-primary:           #F7CF51;
$color-btn-primary-hover:                #DAAA10;
$color-border-btn-secondary:             #D9D9D9;
$color-btn-secondary-background:         #F9F9F9;
$color-btn-secondary-disabled-border:    #BFBFBF;
$color-btn-link-primary:                 #CA9D0E;
$color-btn-link-secondary:               #8c8c8c;
$color-btn-asset-returned:               #27ae60;

$color-input-border-default:             #CECECE;
$color-input-border-focused:             rgba(247, 207, 81, 1);
$color-input-border-error:               #FDA29B;
$color-input-border-asset-returned:      #219653;
$color-input-error-message:              #F04438;
$color-input-disabled:                   #FAFAFA;
$color-base:                             #ffffff;
$color-input-border-disabled:            #D9D9D9;

$color-text-primary:                     #0B0B0B;
$color-text-secondary:                   #1E2934;
$color-text-light:                       #323232b3;
$color-text-light-name:                     #323232;
$color-text-lighter:                     #898989;
$color-text-placeholder:                 #bfbfbf;
$color-text:                             #434343;

$color-divider-default:                  #BFBFBF;
$color-divider-background:               #10364e;

$color-light-grey:                      #F0F0F0;

$color-link-primary:                     #F7CF51;

$color-success:                          #14B467;
$color-no-conflict:                      #219653;
$color-conflict:                         #F2994A;
$color-danger:                           #ff1818;
$color-increase:                         #34c759;
$color-decrease:                         #ff3b30;
$color-up-background:                    #f2faf5;
$color-down-background:                  #fff4f0;


$color-badge-background:                 #F2F4F7;

$color-background:                       #F5F5F5;
$color-background-secondary:             #EFF2F5;
$color-background-left-menu:             #FAFAFA;
$color-background-new:                   #FAFAFA;

$color-background-praise:                #FAFAFA;
$color-background-praise-selected:       #FFF0A6;
$color-border-praise-selected:           #F9C931;
$color-text-praise-count:                #434343;
$color-text-praise-title:                #595959;
$color-text-praise-extra:                #BFBFBF;
$color-text-label:                       #646464;
$color-green-badge:                      #54d09c;

$color-info-heading:                     #8C8C8C;
$color-info-value:                       #262626;

$color-active-anchor:                    #F7CF51;
$color-hover-anchor:                     #ffd700;

$color-background-table-head:            #fafafa;
$color-background-checkbox:              #fffdf0;
$color-border-checkbox:                  #d0d5dd;
$color-exit-widget-header:               #fff7f7;
$color-exit-widget-wrapper:              #eb5757;
$color-exit-bar-background:              #fff9f9;

$color-squad-drawer-header:              #FFFEF0;

$color-background-leave-progress-bar:    #f0eeee;
$color-attendance-border:              #6BBFF5;
$color-attendance-time-slot-background:  #E1D8FF;
$color-attendance-time-slot-border:      #BDABFD;
$color-attendance-line-background:       #f0f0f0;
$color-status-pending:                 #6b7181;
$color-status-in-progress:               #2d9cdb;
$color-status-completed:                #27ae60;
$color-attendance-hover-background:      #0000000d;
$color-time-off-type:                    #323338;
      
$header-height:                            64px;
$sub-header-height:                        82px;
$tabs-height:                              40px;
$menu-normal-width:                        220px;
$sub-menu-width:                           225px;
$collapsed-menu-width:                     56px;
$breakpoint-sm:                            576px;
$breakpoint-md:                            768px;
$breakpoint-lg:                            992px;
$breakpoint-xl:                            1200px;
$breakpoint-xxl:                           1400px;
$breakpoint-xxxl:                          1600px;
$attendance-weekly-header-height:          64px;
         
$font-family:                              "Inter";
