@import "../../styles/variables";

.form-container {
  .title {
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: $color-text-secondary;
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-text-lighter;
    padding: 8px 0px 15px 0px;
  }

  :global {
    .ant-form-item-label label {
      color: $color-text-lighter;
    }
  }

  .submit-btn {
    width: 100%;
  }

  .back-to-login-btn {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .back-to-login {
      display: inline-flex;
      align-items: center;
      gap: 2px;
      color: $color-text-primary;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
}
