.health-wrapper {
	padding: 21px;

	.goal-activity-container {
		display: flex;
		gap: 20px;

		.container-wrapper {
			width: 70%;
			display: flex;
			flex-direction: column;

			.header-wrapper {
				>div:has(.day) {
					width: 100%;
				}
			}
		}

		.pagination-header {
			display: flex;
			align-items: center;
			gap: 1rem;
		}

		.day {
			font-size: 24px;
			font-weight: 600;
			color: rgba(38, 38, 38, 1);
		}
	}

	.card-wrapper {
		display: flex;
		align-items: center;
		flex: 1;
		justify-content: space-between;
		padding: 10px;
		gap: 22px;
		width: 100%;
		border: 1px solid rgba(217, 217, 217, 1);
		border-radius: 8px;
		box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

		.card-text {
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			color: rgba(67, 67, 67, 1);
			padding-bottom: 7px;
		}

		.card-score {
			font-size: 24px;
			font-weight: 600;
			line-height: 30px;
			color: rgba(38, 38, 38, 1);
		}
	}

	.days {
		background-color: rgba(255, 240, 166, 0.52);
		width: 28px;
		height: 61px;
		border-radius: 22px;
		margin-bottom: 15px;
	}

	.progress-subtext {
		color: rgba(89, 89, 89, 1);
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
	}

	.custom-button {
		span {
			text-decoration: unset !important;
		}
	}

	.wrapper {
		width: 30%;
	}
}

.health-insights-card {
	display: flex;
	row-gap: 10px;
	column-gap: 20px;
	flex-wrap: wrap;
	justify-content: unset;
}