@mixin text-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

@mixin text-styles($font-size, $font-weight, $color) {
	font-size: $font-size;
	font-weight: $font-weight;
	color: $color;
}

@mixin skeleton-title($height) {
	:global {
		.ant-skeleton-content {
			.ant-skeleton-title {
				width: 100% !important;
				height: $height;
			}
		}
	}
}
