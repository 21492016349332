.insights-wrapper {
	padding: 20px;
}

.title {
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: -0.01em;
	color: rgba(171, 131, 39, 1);
	margin-bottom: 20px;
}

.subtitle {
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	color: rgba(38, 38, 38, 1);
}

.content {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	color: rgba(38, 38, 38, 1);
	margin-bottom: 16px;
}

.wrapper-top {
	padding: 24px 20px 0px 20px;
}

.wrapper-bottom {
	padding: 0px 20px 24px 20px;
}

.wrapper-content {
	padding: 0px 20px 0px 20px;
}

.pointers {
	padding-bottom: 20px;
	list-style: disc;
	margin-left: 18px;
}
