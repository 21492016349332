.header-right-content {
	display: flex;
	gap: 12px;
	align-items: center;

	.text {
		font-size: 16px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: 0px;
		text-align: left;
	}
}

.steps {
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-bottom: 40px;
}

.form-layout {
	max-width: 480px;
	display: flex;
	gap: 30px;
	flex-direction: column;
	align-items: stretch;

	.password-validation {
		margin-top: 0;
		width: 100%;
	}
	.password-input-wrapper {
		display: block;
	}

	.divider-or {
		display: flex;
		align-items: center;
		justify-content: stretch;
		gap: 20px;

		span {
			font-size: 16px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: 0px;
			text-align: left;
		}

		.divider {
			background: hsla(0, 0%, 85%, 1);
			height: 1px;
			width: 100%;
		}
	}

	.form {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		:global {
			.ant-form-item {
				margin-bottom: 10px;
			}
		}
	}

	.submit-btn {
		width: 100%;
		margin-top: 16px;
	}
}

.date-picker-popup-custom {
	:global {
		.ant-picker-footer {
			display: none;
		}
	}
}
