.goals {
	cursor: pointer;
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 5rem;
	min-height: 232px;
	padding: 16px;
	border: 1px solid rgba(217, 217, 217, 1);
	border-radius: 8px;
	justify-content: space-between;
	overflow: hidden;
}

.goals-wrapper {
	display: flex;
	gap: 10px;
	flex-direction: column;
}

.highlight-border {
	border-color: #f7cf51;
}

.footer {
	width: 58px;
	background-color: rgba(255, 253, 240, 1);
	border-radius: 10px;
	margin-top: 8px;
}

.footer-text {
	padding-left: 10px;
}

.h4 {
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	color: rgba(67, 67, 67, 1);
}

.sub-heading {
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	color: rgba(89, 89, 89, 0.79);
}