.badges {
	filter: grayscale(0) !important;
}

.badges-wrapper {
	display: flex;
	justify-content: space-around;
	align-items: flex-end;
	height: 110px;
	width: 100%;
	margin-bottom: 30px;
	cursor: pointer;
}

.title {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
	color: rgba(38, 38, 38, 1);
	margin-bottom: 30px;
}

.water-drop {
	width: 34px;
	height: 42px;
}

.progress-wrapper {
	display: flex;
	gap: 9px;
	align-items: center;

	.progress-done {
		font-size: 24px;
		font-weight: 600;
		line-height: 30px;
		color: rgba(38, 38, 38, 1);
	}

	.progress-left {
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		color: #595959;
	}
}

.log-water-wrapper {
	padding: 24px 20px;
}

.form-wrapper{
	display: flex;
	gap: 20px;
	justify-content: flex-start;
	align-items: center;

}
