@import "../../styles/variables";

.wrapper {
  .content-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }

  .content-text {
    width: 32px;
    height: 32px;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    border-radius: 50%;
    display: grid;
    place-items: center;
  }
}
