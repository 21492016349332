@import "../../../styles/variables";

.radio {
  position: relative;

  :global {
    .ant-radio-wrapper {
      &:hover {
        .ant-radio-inner {
          border-color: $color-btn-primary;
        }
      }
    }
  }

  &:global.ant-radio-wrapper-checked {
    border-color: $color-input-border-focused !important;
  }

  :global {
    .ant-radio-checked .ant-radio-inner {
      border-color: $color-input-border-focused !important;
      background-color: $color-input-border-focused !important;
      box-shadow: 0px 0px 0px 4px rgba(255, 250, 234, 1),
        0px 1px 2px rgba(16, 24, 40, 0.05) !important;
      outline: none !important;

      &::after {
        background-color: white;
      }

      .ant-checkbox-disabled {
        opacity: 0.4;
      }
    }
  }
}

.radio-two {
  &:global.ant-radio-wrapper {
    margin-right: 16px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 5px 10px;
    min-width: 170px;
    justify-content: center;
  }

  &:global.ant-radio-wrapper-checked {
    border-color: $color-input-border-focused;
    box-shadow: 0px 0px 0px 4px rgba(255, 250, 234, 1),
      0px 1px 2px rgba(16, 24, 40, 0.05);
    // background-color: $color-background;
    background-color: transparent;
  }

  :global {
    .ant-radio-checked .ant-radio-inner {
      border-color: $color-input-border-focused !important;
      background-color: $color-input-border-focused;
      box-shadow: 0px 0px 0px 4px rgba(255, 250, 234, 1),
        0px 1px 2px rgba(16, 24, 40, 0.05) !important;
      outline: none !important;

      &::after {
        background-color: white;
      }
    }
  }
}