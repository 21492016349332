.week {
	padding: 1rem;
	border: 1px solid rgba(217, 217, 217, 1);
	border-radius: 8px;
	width: 100%;
}

.days-wrapper {
	display: flex;
	justify-content: space-around;
	gap: 10px;

	.week-day {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.highlighted {
		border: 1px solid purple;
		padding: 5px;
	}

	.highlighted-day {
		background-color: #f7cf51 !important;
		color: white;
	}

	.tick {
		position: absolute;
		padding-bottom: 30px;
		font-size: 20px;
		color: white;
	}
}

.strength-details {
	display: flex;
	gap: 10px;
	align-items: center;
	padding-left: 18px;
}

.days {
	background-color: rgba(255, 240, 166, 0.52);
	width: 28px;
	height: 61px;
	border-radius: 22px;
	margin-bottom: 15px;
}

.sub-heading {
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	color: rgba(89, 89, 89, 0.79);
}

.strength-values {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: rgba(67, 67, 67, 1);
}

.custom-empty {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	color: rgba(67, 67, 67, 1);
}

h4 {
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	color: rgba(67, 67, 67, 1);

}