.dropdown-wrapper {
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: 8px;
	border: 1px solid #e0e0e0;
	box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.1);
	max-height: 300px;
	overflow: auto;
	scrollbar-width: thin;

	.item {
		padding: 0.75rem;
		display: flex;
		align-items: center;
		gap: 8px;
		margin-right: 1rem;

		.title {
			font-weight: 500;
		}
	}
}
