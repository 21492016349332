@import "../../styles/variables";

.basecard {
	border: 0.5px solid rgb(204, 204, 204, 0.4);
	border-radius: 6px;
	background-color: #fff;
	padding: 26px;
}

.base-card-title {
	font-family: $font-family;
	color: $color-btn-text;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 20px;
}
.base-card-footer {
	margin-top: 20px;
}
