@import "../../styles/variables";
@import "../../styles/extends";

.left-menu {
	width: $menu-normal-width;
	min-width: $menu-normal-width;
	overflow: hidden;
	padding: 0px 0px 14px 0;
	background-color: $color-background-left-menu;
	transition: all ease-in-out 0.2s;
	height: calc(100% - $header-height);
	// box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
	z-index: 1000;
	position: fixed;
	overflow-y: auto;
	border-right: 1px solid $color-gray-border;

	// will add these properties after design rectification

	// &::-webkit-scrollbar {
	//   display: none;
	// }

	// & {
	//   -ms-overflow-style: none;
	//   scrollbar-width: none;
	// }

	:global {
		.ant-menu {
			background-color: inherit;
			border-right: none;

			i.ant-menu-submenu-arrow {
				display: none;
			}

			.ant-menu-item,
			.ant-menu-submenu-title {
				padding-left: 26px !important;
				padding-right: 15px;
				display: flex;

				.anticon,
				.ant-menu-item-icon {
					width: 15px;
					height: 15px;
					opacity: 0;
				}

				&:hover {
					background-color: $color-btn-primary;
				}

				&::after {
					display: none;
				}
			}

			.ant-menu-item {
				height: 36px !important;
				line-height: 36px !important;
				margin: 2px 0 !important;
				padding-left: 27px !important;
			}

			.ant-menu-item-selected {
				background-color: $color-btn-primary !important;
				color: $color-text-secondary !important;
			}

			.ant-menu-submenu-title {
				gap: 10px;
			}

			.ant-menu-sub {
				background-color: $color-background !important;
				padding: 0px !important;
			}
		}
	}

	.route-name {
		color: $color-text-secondary !important;
		display: flex;
		justify-content: space-between;
		font-weight: 600;
	}

	.dropdown-arrow {
		transition: all 0.2s ease-in-out 0s;
	}

	.divider {
		height: 1px;
		background-color: $color-divider-background;
	}

	.menu-label {
		padding: 10px 0 10px 17px;
		margin-top: 1rem;
		font-weight: 600;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&.organisation {
			padding: 10px 17px 10px 19px;
		}
	}

	.arrow-wrapper {
		width: 28px;
		height: 28px;
		border-radius: 4px;
		display: flex;
		margin: 10px 0 0 14px;
		background-color: $color-btn-primary;
		cursor: pointer;

		.arrows-icon {
			width: 12px;
			height: 12px;
			margin: auto;
			transform: rotate(0deg);
			user-select: none;
			transition: all ease-in-out 0.2s;
		}
	}

	.gradient{
		@extend .left-menu-linear-gradient;
	}
}

.collapsed-left-menu {
	width: $collapsed-menu-width;
	min-width: $collapsed-menu-width;

	.arrow-wrapper .arrows-icon {
		transform: rotate(180deg);
	}

	.route-name {
		display: none;
	}
}
// moved this to the root level because the base class was not matching.
.menu-item {
	display: flex;
	padding: 10px 18px;
	cursor: pointer;
	color: $color-btn-text;
	align-items: center;
	user-select: none;
	white-space: nowrap;
	margin: 2px 0;
	height: 36px;
	font-weight: 500;
	transition: all ease-in-out 0.2s;

	&:first-of-type {
		margin-top: 0px;
	}

	.menu-item-icon {
		margin-right: 10px;
		height: 20px;
		width: 20px;
	}

	&:hover {
		background-color: $color-btn-primary;
	}

	.title {
		opacity: 1;
		transition: opacity ease-in-out 0.2s;
	}

	.hide-title {
		opacity: 0;
	}
}

.menu-item.active-menu-item {
	background-color: $color-btn-primary;
	font-weight: 600;
}
