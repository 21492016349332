@import "../../../styles/variables";

.checkbox {
  position: relative;
  margin-left: 0 !important;

  :global {
    .ant-checkbox-inner {
      border-color: $color-text-primary;
      background-color: transparent;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #588bf5 !important;
      border-color: #588bf5 !important;

      &::after {
        border-color: white !important;
      }
    }

    .ant-checkbox-disabled {
      opacity: 0.4;
    }
  }
}

.custom-checkbox {
  align-items: center;

  &:global(.ant-checkbox-wrapper) {
    &:hover {
      :global {
        .ant-checkbox-checked {
          &:not(.ant-checkbox-disabled) {
            .ant-checkbox-inner {
              background-color: $color-background-checkbox !important;
            }
          }
        }

        .ant-checkbox {
          &:not(.ant-checkbox-disabled) {
            .ant-checkbox-inner {
              border-color: $color-btn-link-primary !important;
            }
          }
        }
      }
    }

    :global {
      .ant-checkbox {
        .ant-checkbox-inner {
          width: 20px;
          height: 20px;
          border-radius: 6px;
          border: 1px solid $color-border-checkbox;
        }
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $color-background-checkbox !important;
          border-color: $color-btn-link-primary !important;
        }

        .ant-checkbox-inner::after {
          border-color: $color-btn-link-primary !important;
          margin-left: 1px;
        }
      }

      .ant-checkbox-checked::after {
        border: 0px;
      }

      span {
        font-size: 14px;
        color: $color-text-praise-title;
        font-weight: 500;
      }
    }
  }
}

.hide-checkbox{
  user-select: none !important;
  :global{
    .ant-checkbox {
      display: none !important;
      .ant-checkbox-inner {
        display:none !important;
      }
    }
  }
}
