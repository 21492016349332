@import "../../styles/variables";

.file {
  border: 0.5px solid rgba(102, 102, 102, 0.4);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  color: $color-text-primary;
  width: max-content;
  position: relative;

  .file-name {
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
  }

  &.no-border {
    border: none;
    padding: 0px;

    .file-name {
      font-size: 14px !important;
      line-height: 16px !important;
      text-decoration: underline;
    }
  }

  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    >* {
      padding: 0;
    }
  }
}