.wrapper {
	:global {
		.ant-modal-header {
			margin-bottom: 0;
		}

		.ant-modal-content {
			border-radius: 8px;
			max-height: 80vh;
			padding: 0;
		}

		.ant-modal-body {
			padding: 25px;
			overflow: auto;
		}

		.ant-modal-footer {
		}
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 24px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		background-color: #ffffff;
		border-radius: 8px 8px 0 0;

		.title {
			letter-spacing: 0.03em;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 120%;
			margin-bottom: 0;
		}

		.header-right-content {
			display: flex;
			align-items: center;
			gap: 20px;

			.close-button {
				border: none;
				background: transparent;
				cursor: pointer;
				display: grid;
				place-items: center;
			}
		}
	}
}
