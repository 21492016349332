.steps {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 40px;
}

.wrapper {
  display: flex;
  max-width: 180px;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  flex: 1;
  padding: 2px;

  .bar {
    height: 4px;
    border-radius: 2px;
    width: 100%;
    background-color: hsla(0, 0%, 85%, 1);
    margin-bottom: 4px;
  }

  .active {
    background-color: hsla(48, 100%, 75%, 1);
  }

  .prev {
    background-color: hsla(145, 63%, 42%, 1);
  }

  .number {
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
  }

  .text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.faded {
  opacity: 0.5;
}
