@import "../../../../styles/variables";

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}

.candidate-shortlist-form {
  padding: 20px 16px !important;

  .detail-row {
    .row-title {
      padding-right: 1rem;
      padding-bottom: 1rem;
    }
  }
}

.skill-table {
  margin-bottom: 1rem;
  :global .ant-table {
    th.ant-table-cell[colspan="5"] {
      display: none;
    }

    .ant-form-item-control-input {
      min-height: 0px;
    }

    .ant-table-row .ant-table-cell .ant-form-item {
      margin-bottom: 0;
    }
    .ant-form-item-control-input,
    th {
      text-align: center;
    }
  }
}
