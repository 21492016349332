.wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	background: #ffffff;
	min-height: 100vh;

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1.5rem;
		position: sticky;
		top: 0;
		width: 100%;
		z-index: 9999;
		background-color: #ffffff;

		svg {
			max-width: 180px;
		}
	}

	.content {
		flex: 1;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
