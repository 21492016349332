@import "../../styles/variables";

.dragger-wrapper {

  .upload-body {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    padding: 14px 0px;
  }

  .upload-icon {
    font-size: 20px;
  }

  .upload-text {
    font-size: 14px;
    color: #8c8c8c;
  }

  .uploading-text {
    font-size: 14px;
    margin-top: 9px;
  }
  :global {
    .ant-upload {
      background-color: transparent !important;
      border-style: solid !important;

      &:not(.ant-upload-disabled):hover {
        border-color: $color-input-border-focused !important;
      }
    }
  }

}