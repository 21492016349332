@import "../../../styles/variables";

.main-wrapper {
  padding-top: $header-height;

  .content-layout {
    width: calc(100% - $menu-normal-width);
    margin-left: $menu-normal-width;
    transition: all ease-in-out 0.2s;

    &.collapsed-content-layout {
      width: calc(100% - $collapsed-menu-width);
      margin-left: $collapsed-menu-width;
    }

    .inner {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      min-height: calc(100vh - $header-height - 8px);
      background-color: white;
    }
  }
}
