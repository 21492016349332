.insights-card-wrapper {
	padding: 0px !important;
	cursor: pointer;
	width: 100%;
	border: 1px solid rgba(217, 217, 217, 1);
	border-radius: 8px;
	height: 100%;
}

.insights-text {
	background-color: rgba(255, 254, 240, 1);
	padding: 1rem 1.25rem;
	border-bottom: 1px solid rgba(217, 217, 217, 1);
	display: flex;
	align-items: center;
	border-radius: 8px 8px 0px 0px;

	.insights-title {
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: -0.01em;
		text-align: left;
		color: rgba(67, 67, 67, 1);
		padding-left: 12px;
	}
}

.insights {
	padding: 1rem 1.25rem;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: -0.01em;
	text-align: left;
	color: rgba(89, 89, 89, 0.79);
}