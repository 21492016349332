@import "../../styles/variables";

.wrapper {
	.top-panel {
		display: flex;
		align-items: center;
		padding: 1.5rem 0 1rem;
		justify-content: space-between;

		.top-left-wrapper {
			width: 50%;

			.search-wrapper {
				position: relative;

				.icon-img {
					width: 15px;
					position: absolute;
					left: 8px;
					top: 50%;
					transform: translate(0, -50%);
				}

				.input-search {
					background-color: rgba(242, 244, 244, 0.4) !important;
					font-size: 14px;
					font-weight: 500;
					color: $color-text-lighter;
					position: relative;
					height: 36px;
					padding-left: 30px;
					max-width: 400px;
				}
			}
		}
	}

	.table-wrapper {
		position: relative;
		:global {
			.ant-table-footer {
				background: transparent;
				border-radius: 0 0 4px 4px;
			}

			.ant-table {
				background-color: transparent;

				.ant-table-container {
					border-start-start-radius: 4px;
					border-start-end-radius: 4px;
				}
			}

			.ant-table-thead > tr > th {
				text-transform: uppercase;
				color: $color-text-light;
				background: #fff;
				border-color: #f0f0f0;
				padding: 12px 8px;
				font-weight: 500;
				font-size: 12px;

				&::before {
					display: none;
				}
			}

			.ant-table-thead > tr:first-child > th:last-child {
				border-start-end-radius: 4px !important;
			}

			.ant-table-thead .ant-table-cell-fix-right {
				// background-color: #fff;
			}

			.ant-table-tbody > tr.ant-table-placeholder:hover > td {
				background-color: transparent;
			}

			.ant-table-tbody > tr > td {
				border-color: #f0f0f0;
				padding: 12px 8px;
			}

			.ant-table-tbody > tr.ant-table-row-selected > td,
			.ant-table-tbody > tr.ant-table-row-selected:hover > td {
				background: $color-background;
			}

			.ant-checkbox-inner {
				border: 1px solid rgba(50, 50, 50, 0.7);
				border-radius: 3px;
			}

			.ant-checkbox-checked .ant-checkbox-inner {
				border-color: #1890ff;
			}

			.ant-table-thead th.ant-table-column-has-sorters:hover {
				background: none;
			}

			td.ant-table-column-sort {
				background: none;
			}

			.ant-pagination {
				display: flex;
				align-items: center;
				justify-content: center;

				// .ant-pagination-prev {
				// 	height: 36px !important;
				// 	width: 48px;
				// }

				:global {
					li {
						height: 100% !important;
						aspect-ratio: 1/1;
						button,
						a {
							height: 100% !important;
							aspect-ratio: 1/1;
							display: grid;
							place-items: center;
							border: none;
						}
					}

					.ant-pagination-item,
					button {
						border: none;
						font-weight: 500;
					}

					.ant-pagination-item a {
						color: #667085;
					}

					.ant-pagination-prev .ant-pagination-item-link,
					.ant-pagination-next .ant-pagination-item-link {
						border: none;
					}

					.ant-pagination-item-active a {
						color: #f7cf51;
						// border: 1px solid #d9d9d9;
						border-radius: 8px;
						background-color: #fafafa;
					}

					.ant-pagination-options {
						display: none;
					}
				}
				.ant-pagination-prev,
				.ant-pagination-next {
					.ant-btn {
						height: 36px !important;
						width: 48px;
					}
				}
			}
		}
	}

	.dark-header {
		:global {
			.ant-table {
				.ant-table-container {
					.ant-table-content {
						.ant-table-thead > tr > th {
							background: $color-background-table-head !important;
							height: 35px;
							color: $color-text;
							.ant-table-cell {
								padding-left: 16px;
							}
						}
						.ant-table-tbody {
							.ant-table-cell {
								padding: 16px;
							}
						}
					}
				}
			}
		}
	}
}

.pagination-buttons {
	border-radius: 8px;
	padding: 0px !important;
	border: 1px solid #d0d5dd !important;
	height: 36px !important;
}

.next {
	> svg {
		transform: rotate(180deg);
	}
}
