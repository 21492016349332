@import "../../../../../styles/variables";

.popconfirm-btns {
  :global .ant-btn {
    display: unset;
    min-height: unset;
    height: unset;
    padding: 6px 12px;
  }
}

.add-team-member {
  // padding: 20px 16px !important;

  .detail-row {
    .skills-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .skills {
        display: flex;
        align-items: center;
        gap: 18px;

        .skill {
          display: flex;
          align-items: center;
          gap: 8px;
          background: #588bf5;
          border-radius: 40px;
          padding: 6px 12px;

          .skill-text {
            color: #ffffff;
            font-weight: 500;
            font-size: 12px;
          }

          .delete-skill {
            cursor: pointer;
          }
        }

        .rating {
          color: rgba(50, 50, 50, 0.85);
          font-weight: 500;
          font-size: 12px;
        }
      }
    }
  }

  .add-education-field {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    float: left;

    .add-text {
      color: #3498db;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .add-field {
    margin-top: 30px;
  }

  .remove-field-col {
    margin-left: auto;
    margin-right: 0;
  }

  .remove-field {
    display: flex;
    align-items: center;
    float: right;
    gap: 6px;
    cursor: pointer;

    .remove-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #c73736;
    }
  }

  .skills-radio-group {
    display: flex;
    gap: 48px;
  }
}