.wrapper {
  .steps {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 40px;
  }
}

.upload {
  :global {
    .ant-upload {
      background-color: rgba(0, 0, 0, 0.02) !important;
      border-style: dashed !important;
    }
  }

  .dragger-body {
    flex-direction: column;
    gap: 0.5rem !important;
    font-weight: 500;
  }
}