@import "../../../styles/variables";

.switch {
	position: relative;

	&:global.ant-switch {
		background-color: #3232322e;
	}

	&:global.ant-switch-checked {
		background-color: $color-btn-primary;

		&:hover {
			&:not(.ant-switch-disabled) {
				background-color: $color-btn-primary-hover ;
			}
		}

		.ant-switch-handle::before {
			background-color: #ffffff;
		}
	}
}