@import "../../../styles/variables";

.onboarding-layout{
    display: flex;
    justify-content: flex-start;
    margin-top: 64px;
    height: calc(100vh - 64px);
    overflow: hidden;

    .left-side{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap:20px;
      min-width: 296px;
      max-width: 300px;
      background: $color-input-disabled;
    }

    .right-side{
      width:100%;
      flex:1;
    }
}