.metrics-wrapper {
	margin-top: 20px;
}

.drawer-subtitle {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
	color: rgba(38, 38, 38, 1);
}

.customize-today-wrapper {
	padding: 24px 20px;
}

.metrics {
	display: flex;
	justify-content: space-between;
	padding-bottom: 20px;

	.metrics-buttons {
		width: 48%;
	}
}
.values{
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	color: rgba(89, 89, 89, 1);
}
