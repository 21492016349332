.badges {
  display: flex;
  row-gap: 10px;
  column-gap: 40px;
  flex-wrap: wrap;
}
.color-badge{
  filter: grayscale(0);
}
.color-name{
  color:rgba(38,38,38,1);
}