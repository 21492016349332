.badgeContainer {
	width: 70px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.badge {
	filter: grayscale(1);
}

.name {
	filter: grayscale(1);
	color: rgba(38, 38, 38, 70%);
	padding: 10px 10px 0px 10px;
	text-align: center;
}

.description {
	line-height: 1.25;
	font-size: 14px;
	text-align: center;
	padding-top: 10px;
}