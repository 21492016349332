@import "../../styles/variables";

.container {
	.header-title {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 140%;
		color: $color-text-secondary;
		padding-bottom: 20px;
		text-align: center;
	}

	:global {
		.ant-form-item-label label {
			color: $color-text-lighter;
			font-size: 13px;
		}
	}

	.reset-submit-btn {
		width: 100%;
	}
	.back-to-login-btn {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .back-to-login {
      display: inline-flex;
      align-items: center;
      gap: 2px;
      color: $color-text-primary;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
}
