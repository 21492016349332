@import "../../styles/variables";

.container {
  height: calc(100vh - $header-height);

  .confirm-text {
    color: #1a2028;
    text-align: center;
    padding: 65px 20px 20px;

    .text-first {
      font-weight: 500;
      margin: 0 0 12px;
      font-size: 18px;
    }
  }
}
