.card-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	gap: 22px;
	width: 100%;
	max-width: 215px;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

	.card-text {
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: -0.01em;
		color: rgba(67, 67, 67, 1);
		padding-bottom: 7px;
	}

	.card-score {
		font-size: 24px;
		font-weight: 600;
		line-height: 30px;
		letter-spacing: -0.01em;
		color: rgba(38, 38, 38, 1);
	}
}

.data-cards {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	padding-bottom: 18px;

	.footer-text {
		padding-left: 10px;
	}
}

.header-wrapper {
	padding: 16px 2px;
}

.title {
	width: 100%;
}

.h4 {
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	color: rgba(67, 67, 67, 1);
}

.sub-heading {
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	padding-top: 8px;
	color: rgba(89, 89, 89, 0.79);
}
