@import "../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .tick-icon {
    width: 80px;
    height: 80px;
  }

  .title {
    font-size: 22px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 16px;
    color: $color-text-primary;
  }

  .login-btn {
    width: 100%;
  }
}
