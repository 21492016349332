@import "../../styles/variables";

.form-container {
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
  }

  :global {
    .ant-form-item-label label {
      color: $color-text-lighter;
    }
  }

  .error-message {
    display: flex;
    align-items: center;
    background-color: #fffef0;
    border-radius: 4px;
    padding: 7px;
    gap: 5px;
    margin-bottom: 7px;
    margin-top: 8px;
    .alert-image {
      width: 15px;
      height: 15px;
      stroke: $color-input-error-message;
      stroke-width: 1px;
    }
    .alert-text {
      color: $color-input-error-message;
    }
  }

  .under-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;

    :global {
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }

    .checkbox-title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: rgba(0, 0, 0);
    }

    .forgot-password {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $color-btn-link-primary;
    }
  }

  .form-submit-btn {
    width: 100%;
  }

  .login-heading {
    margin-top: 18px;
  }
}
