@import "../../../styles/variables";

.datepicker-wrapper {
  .datepicker {
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    height: 40px;
    box-shadow: none;
    border-radius: 4px !important;
    width: 100%;

    &:global(.ant-picker-focused) {
      border: 1px solid $color-input-border-focused !important;
      box-shadow: 0px 0px 0px 4px rgba(255, 250, 234, 1),
        0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    }

    &:global(.ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)) {

      // added this class to increase specificity
      .ant-select-selector {
        border-color: $color-input-border-error !important;
        box-shadow: none !important;
      }
    }

    &:global(.ant-picker-status-error) {
      border-color: $color-input-border-error !important;
    }
  }
}

.datepicker-popup {
  :global {
    .ant-picker-panel-container {
      background-color: #ffffff !important;
      box-shadow: 0px 1px 3px rgba(71, 71, 71, 0.23) !important;
      border-radius: 8px !important;
      border: 1px solid #f7cf51;

      .ant-picker-panel {
        border: none;
      }

      tbody {
        height: 100%;

        tr {
          td {
            margin: 0 auto;
          }
        }
      }

      .ant-picker-header {
        border: none;

        button,
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-btn-text !important;

          &:hover {
            color: #f7cf51;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .ant-picker-header-prev-btn,
      .ant-picker-header-next-btn {
        svg {
          width: 21px;
          height: 21px;
        }
      }

      .ant-picker-footer {
        border: none;
        margin-left: auto;
      }

      .ant-picker-cell:not([disabled]):hover .ant-picker-cell-inner {
        background-color: rgba($color-btn-primary, 0.4) !important;
        border-radius: 50%;
      }

      .ant-picker-cell-in-view {
        &.ant-picker-cell-selected:not([disabled]) .ant-picker-cell-inner {
          background-color: $color-btn-primary !important;
          border-radius: 50%;
          color: black;
        }

        &.ant-picker-cell-today .ant-picker-cell-inner::before {
          border-radius: 50%;
          border-color: $color-btn-primary;
        }
      }

      .ant-picker-year-panel {
        .ant-picker-header-view button:hover {
          color: inherit;
        }

        .ant-picker-cell:not([disabled]):hover .ant-picker-cell-inner {
          border-radius: 6px;
        }

        .ant-picker-cell-in-view {
          &.ant-picker-cell-selected:not([disabled]) .ant-picker-cell-inner {
            border-radius: 6px;
          }

          &.ant-picker-cell-today .ant-picker-cell-inner::before {
            border-radius: 6px;
          }
        }
      }

      .ant-picker-month-panel {
        .ant-picker-cell-in-view {
          &:hover {
            .ant-picker-cell-inner {
              border-radius: 2px;
            }
          }

          &.ant-picker-cell-selected {

            &:not([disabled]) {
              .ant-picker-cell-inner {
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
  }
}