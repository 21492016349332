@import "../../styles/variables";

.dropdown-class {
	:global {
		.ant-dropdown-menu {
			background-color: #ffffff;
			box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.15);
			border-radius: 7px;
		}
	}
}

.sub-menu-icon {
	cursor: pointer;
	padding: 8px;

	&:hover {
		background-color: $color-background;
		padding: 8px;
	}
}

.header-two-wrapper {
	width: 100%;
	padding: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.right-content {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 20px;
		:global {
			.ant-pagination-item {
				display: none;
			}

			.ant-pagination-item-link {
				border: none;
			}

			.ant-pagination-total-text {
				&:hover {
					cursor: pointer;
				}
			}
		}

		&.right-sided-pagination {
			justify-content: flex-end;
			width: 100%;
		}

		.right-pagination {
			margin-left: auto;
		}

		.buttons {
			display: flex;
			align-items: center;
			gap: 20px;
			justify-self: flex-end;

			.search-wrapper {
				position: relative;
				height: 36px;
				display: flex;
				align-items: center;

				.input {
					width: 0px;
					border: none;
					text-transform: none;
					transition: all 0.3s ease;
				}

				.search-icon {
					position: absolute;
					cursor: pointer;
					left: -11px;

					img {
						width: 20px;
					}
				}

				.cancel-icon {
					display: none;
				}
			}

			.search-wrapper-expanded {
				.input {
					all: unset;
					background-color: $color-background;
					border-radius: 4px;
					padding: 10px 0px 10px 25px;
					width: 350px;
					font-size: 14px;
					transition: all 0.6s ease !important;

					@media screen and (max-width: 1000px) {
						width: 300px;
					}

					@media screen and (max-width: 800px) {
						width: 200px;
					}
				}

				.search-icon {
					left: 6px;
					cursor: text;

					img {
						width: 16px;
					}
				}

				.cancel-icon {
					width: 15px;
					height: 15px;
					position: absolute;
					right: 8px;
					display: block;
					cursor: pointer;
				}
			}
		}
	}
}
