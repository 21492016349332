@import "../../../styles/variables";

.wrapper {
  :global {
    .ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
      border-color: $color-input-border-error;
    }
  }

  .input-number {
    outline: none;
    height: 40px;
    box-shadow: none;
    border-radius: 4px;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    :global {

      .ant-input-number-focused {
        border: 1px solid $color-input-border-focused;
        box-shadow: 0px 0px 0px 4px rgba(255, 250, 234, 1),
          0px 1px 2px rgba(16, 24, 40, 0.05);
      }

      .ant-input-number-input {
        height: 40px;
      }

      .ant-input-number-outlined:hover {
        border-color: $color-input-border-default;
      }

      .ant-input-number-outlined.ant-input-number-status-error:not(.ant-input-number-disabled) {
        border-color: $color-input-border-error !important;
        box-shadow: none !important;

      }

      .ant-form-item {
        margin-bottom: 0 !important;
      }

      .ant-input-number-group-addon {
        &:has(.ant-select) {
          padding: 0;
        }

        .ant-select {
          margin: 0 !important;

          &,
          &:focus {
            .ant-select-selector {
              border: none !important;
              box-shadow: none !important;
            }
          }
        }

        .ant-select-focused {
          .ant-select-selector {
            color: $color-btn-text;
          }
        }

      }
    }
  }

  .dropdown-icon {
    width: 10px;
    opacity: 0.6;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}