@import "../../../styles/variables";

.wrapper {
  :global {
    .ant-select {
      .ant-select-selector {
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        line-height: 38px !important;
        min-height: 38px !important;
        border-radius: 4px !important;
      }

      &:global(.ant-select-focused) {
        .ant-select-selector {
          border: 1px solid $color-input-border-focused !important;
          box-shadow: 0px 0px 0px 4px rgba(255, 250, 234, 1),
            0px 1px 2px rgba(16, 24, 40, 0.05) !important;
        }
      }

      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        line-height: 36px !important;
      }
    }
  }

  :global {
    .ant-select-dropdown {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(71, 71, 71, 0.23) !important;
      border-radius: 0 0 7px 7px !important;
    }
  }
}
