@import "../../styles/variables";

.root {
	:global {
		.ant-popconfirm-buttons {
			display: flex;
			margin-top: 10px;
		}

		.ant-popconfirm-message {
			flex-direction: column;
			gap: 5px;
		}

		button {
			border-radius: 8px;
		}
	}

	.okButton {
		width: 100%;
		background-color: $color-btn-background-primary;
		border: 1px solid #f9c939;
		box-shadow: 0px 1px 2px 0px #1018280d;
		margin-inline-start: 12px !important;

		&:hover,
		&:focus-visible {
			color: $color-btn-text !important;
			background: $color-btn-primary-hover !important;
			border-color: #daaa10;
			opacity: 0.9;
		}

		&:focus {
			color: $color-btn-text;
		}

		&[disabled],
		&[disabled]:hover {
			background-color: #fff9cf;
			color: #bfbfbf;
			border-color: #fff9cf;

			svg {
				stroke: $color-border-btn-secondary;
			}
		}
	}

	.cancelButton {
		width: 100%;
		background-color: #ffffff;
		border: 1px solid $color-border-btn-secondary !important;
		margin-inline-start: 0 !important;

		&:hover,
		&:focus-visible {
			color: $color-btn-text !important;
			background-color: $color-btn-secondary-background !important;
			border: 1px solid $color-border-btn-secondary !important;
		}

		&:focus {
			color: $color-btn-text;
		}

		&[disabled],
		&[disabled]:hover {
			background-color: #ffffff;
			color: $color-border-btn-secondary;
			border: 1px solid $color-btn-secondary-disabled-border;

			svg {
				stroke: $color-border-btn-secondary;
			}
		}
	}
}