@import "variables";
// @import "antd/dist/reset.css"; 
//Not using this
//Reset is overriding the properties in this file.

html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
h1,
h2,
h3,
h4,
h5,
h6,
button,
input {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  // font-size: 100%;
  // font-family: inherit;
  // vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: $color-link-primary;
  text-decoration: none; //This was provided by antd in v4.
  cursor:pointer; // This was also provided by antd in v4
  &:hover {
    color: $color-link-primary;
  }
}