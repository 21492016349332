@import "../../styles/variables";

.modal-wrapper {
	.header-icon-wrapper {
		display: flex;
		border-radius: 10px;
		padding: 12px;
		background-color: $color-btn-primary;
		width: max-content;
		margin-bottom: 20px;
	}

	.heading {
		font-size: 20px;
		font-weight: 600;
		line-height: 24px;
		color: $color-btn-text;
		margin-bottom: 8px;
	}

	.description {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		color: $color-text;
		margin-bottom: 20px;
	}

	:global {
		.ant-form-item {
			margin-bottom: 0px;
		}
	}

	.footer-wrapper {
		margin-top: 32px;

		> * {
			flex: 1;
		}
	}
}
