.started-wrapper {
	padding: 21px;
}

.title {
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
	color: rgba(38, 38, 38, 1);
	margin-bottom: 6px;
}

.subtitle {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	color: rgba(89, 89, 89, 1);
	margin-bottom: 20px;
}

.wrapper {
	display: flex;
	gap: 66px;
	align-items: center;
	padding: 12px 16px;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
	margin-bottom: 20px;
	height: 74px;
	cursor: pointer;
}

.base-card-disabled {
	cursor: not-allowed;
}

.wrapper.active {
	background-color: rgba(255, 254, 240, 1);
	border: 1px solid rgba(247, 207, 81, 1);
}

.card-text {
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	color: rgba(38, 38, 38, 1);
}

.button-wrapper {
	display: flex;
	gap: 16px;
	margin-top: 20px;

	.buttons {
		width: 48%;
	}
}
