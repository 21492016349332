@import "../../../styles/variables";

.wrapper {
	background-color: $color-background-new;
	min-height: 100vh;
	position: relative;
	width: 100%;

	.logo-wrapper {
		display: flex;
		justify-content: left;
		position: sticky;
		background-color: $color-background-new;
		top: 0;
		height: $header-height;
		z-index: 1000;
		padding: 0px 30px;

		.logo-img {
			width: 200px;
		}
	}
}
