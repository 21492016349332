@import "../../../styles/variables";

.wrapper {

  .dropdown-icon {
    width: 10px;
    opacity: 0.6;
  }

  :global {
    
    .ant-select-single {
      height: 40px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: 100%;
    }

    .ant-select {
      .ant-select-selector {
        border: 1px solid $color-input-border-default !important;
        min-height: 40px !important;
        border-radius: 4px !important;
      }

      &:global(.ant-select-disabled) {
        .ant-select-selector {
          background-color: $color-input-disabled !important;
          border-color: $color-input-border-disabled !important;
        }
      }

      &:global(.ant-select-focused) {
        .ant-select-selector {
          border: 1px solid $color-input-border-focused !important;
          box-shadow: 0px 0px 0px 4px rgba(255, 250, 234, 1),
            0px 1px 2px rgba(16, 24, 40, 0.05) !important;
        }
      }

      &:global(.ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)) {

        // added this class to increase specificity
        .ant-select-selector {
          border-color: $color-input-border-error !important;
          box-shadow: none !important;
        }
      }

      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        line-height: 36px !important;
      }

      &.ant-select-multiple {

        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          align-items: center;
        }

        .ant-select-selection-item {
          border-radius: 40px;
          border: none;
          background-color: $color-tag;
          color: white;
          font-weight: 500;
          font-size: 12px;

          .anticon-close path {
            fill: white;
          }
        }
      }
    }
  }

  :global {
    .ant-select-dropdown {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(71, 71, 71, 0.23) !important;
      border-radius: 0 0 7px 7px !important;
    }
  }
}