.waterlog-button {
	text-decoration: unset;
	margin-top: 12px;

	span {
		text-decoration: unset !important;
	}
}

.week {
	margin-bottom: 18px;
	padding: 16px;
	border: 1px solid rgba(217, 217, 217, 1);
	border-radius: 8px;
}

.progress-text {
	font-size: 24px;
	font-weight: 600;
	line-height: 30px;
	color: rgba(38, 38, 38, 1);
}

.progress-subtext {
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	color: rgba(89, 89, 89, 1);
	padding-left: 8px;
}

.wrapper {
	display: flex;
	justify-content: space-between;
}

.water-progress-wrapper {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.water-progress-title {
	color: rgba(67, 67, 67, 1);
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
}