@import "./variables";

.button-reset {
	all: unset;
	cursor: pointer;
}

.optional_holiday {
	font-size: smaller;
	color: #888;
}

.asset-divider {
	border-color: $color-input-border-disabled;
}

.scrollbar {
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: hsl(0, 0%, 90%) hsl(0, 0%, 97%);
	::-webkit-scrollbar-thumb {
		border-radius: 8px;
	}
}

.left-menu-linear-gradient {
	background: linear-gradient(
		90.71deg,
		rgba(123, 79, 250, 0.1) 5.55%,
		rgba(230, 69, 121, 0.1) 49.13%,
		rgba(244, 170, 65, 0.1) 97.28%
	);
}
