.wrapper {
	height: 84px;
	background-color: #fffef0;
	border: 1px solid #d9d9d9;
	box-shadow: 2px 12px 16px -4px rgba(16, 24, 40, 0.08);
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	padding: 22px;

	.content-icon {
		display: flex;
	}

	.content {
		margin-left: 22px;

		.title {
			font-family: Inter;
			font-size: 16px;
			font-weight: 700;
			line-height: 20px;
			letter-spacing: -0.01em;
			text-align: left;
			color: rgba(38, 38, 38, 1);
		}

		.subtitle {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			text-align: left;
			color: rgba(67, 67, 67, 1);
		}
	}

	.cross-icon {
		color: rgba(89, 89, 89, 1);
		cursor: pointer;
	}
}

.connect {
	background-color: rgba(240, 216, 255, 1);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 41px;

	.connect-heading {
		color: rgba(30, 30, 30, 1);
		font-size: 24px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: -0.01em;
		padding-bottom: 4px;
		width: 565px;
	}

	.connect-subheading {
		color: rgba(30, 30, 30, 1);
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		padding-bottom: 16px;
		width: 565px;
	}
}
