@import "../../../../styles/variables";

.wrapper {
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	gap: 26px;
	max-width: 900px;
	word-break: break-word;
	color: $color-info-value;

	> svg {
		height: 70px;
		width: 70px;
	}
	.description {
		line-height: 24px;
		font-size: 16px;
		font-weight: 500;
		color: #434343;
	}

	.reporting-manager-wrapper {
		display: flex;
		align-items: center;
		gap: 10px;

		.reporting-manager-info {
			display: flex;
			flex-direction: column;
			gap: 4px;

			.reporting-manager-name {
				font-size: 18px;
				font-weight: 700;
				line-height: 1.5;
			}

			.reporting-manager-position {
				font-size: 14px;
				font-weight: 500;
				line-height: 1.5;
			}
		}
	}
}
