@import "../../styles/variables";

.wrapper {
	display: grid;
	place-items: center;
	text-align: center;

	.content {
		width: 246px;
		text-align: center;

		.img {
			width: 200px;
			height: 150px;
		}

		.title {
			color: rgba(38, 38, 38, 1);
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 21px;
			margin-bottom: 10px;
		}

		.description {
			color: rgba(89, 89, 89, 1);
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;

			&.only-description {
				margin-top: -20px;
			}

			&.no-img {
				margin-top: -5px;
				color: $color-btn-link-secondary;
				font-size: 14px;
				font-weight: 200;
				text-align: left;
			}
		}
	}
}

.heightfull {
	height: calc(100vh - 205px);
}

.empty-btn-wrapper {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	gap: 10px;
}