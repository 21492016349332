@import "../../styles/variables";

.container {
  padding-top: 16px;
  padding-bottom: 43px;

  .wrapper {
    width: min(100% - 32px, 858px);
    margin-inline: auto;
    background-color: #ffffff;
    padding: 32px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    .intro-para {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 16px;
      margin-bottom: 32px;

      .head-text {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #1a2028;
      }
    }

    .basic-info {
      margin: 32px 0 16px 0;
      border-radius: 4px;
      background: $color-background;

      .section-title {
        padding: 10px;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }
    }
    .schedule-button {
      margin-top: 30px;
    }
  }
}
