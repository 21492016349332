@import "../../../styles/variables.scss";
@import "../../../styles/extends.scss";

.wrapper {
	display: flex;
	align-items: stretch;
	background: #fffef0;
	color: $color-btn-text;
	position: relative;

	.sub-menu {
		width: $sub-menu-width;
		display: flex;
		overflow: hidden;
		height: max-content;
		position: sticky;
		top: $header-height;

		.column {
			transition: all 0.1s ease-in-out;
			min-width: $sub-menu-width;
			width: $sub-menu-width;
		}

		.title {
			margin: 1rem 0 1.5rem 0;
			font-size: 16px;
			font-weight: 600;
			padding: 10px 13px;
		}

		.sub_menu_title {
			font-size: 16px;
			font-weight: 600;
			padding: 16px 16px 10px 13px;
			margin: 0.5rem 0 1rem 0;
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			gap: 1rem;
		}

		.group-name {
			margin: 1rem 0 0.5rem 0;
			font-size: 14px;
			font-weight: 700;
			padding: 10px 13px;
		}

		.link {
			padding: 10px 24px;
			font-weight: 500;
		}

		.sub-menu-switcher {
			@extend .button-reset;
			display: block;
			box-sizing: border-box;
			width: 100%;
			padding: 10px 24px;
			font-weight: 500;
			cursor: pointer;
			margin: 2px 0;
			background: none;
			color: inherit;

			&:hover {
				background: $color-btn-primary;
				color: inherit;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		min-height: calc(100vh - $header-height);
		background-color: white;
		border-left: 1px solid #d9d9d9;
		width: calc(100% - $sub-menu-width);
		overflow: auto;
	}

	.approval-count {
		color: red;
	}
}