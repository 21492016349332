.button-wrapper {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  padding: 12px 25px;
}

.add-item {
  margin-left: 10px;
  text-decoration: underline;
  color: #000;
}

.select-dropdown {
  :global {
    .ant-select-item-empty {
      min-height: 0px;
    }
  }
}
