@import "../../../styles/variables.scss";

.mobile {
  :global {
    .PhoneInputCountry {
      margin-right: 11px;
    }
    .PhoneInputCountryIcon {
      transform: scale(1.2);
      margin-right: 4px;
    }
  }

  input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    height: 40px;
    box-shadow: none;
    border-radius: 4px;
    padding: 4px 11px;

    &:focus {
      border: 1px solid $color-input-border-focused !important;
      box-shadow: 0px 0px 0px 4px rgba(255, 250, 234, 1),
        0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    }
  }
}
