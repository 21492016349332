.badges-wrapper {
	display: flex;
	column-gap: 10px;
	row-gap: 24px;
	flex-wrap: wrap;
	padding: 24px 20px;
	justify-content: space-evenly;
	align-items: stretch;
}

.wrapperClassName {
	width: 150px;
	padding: 8px;
}
