@import "../../styles/variables";

.wrapper {
  background-color: white;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  // top: $header-height;
  z-index: 999;
  padding: 0.75rem 1.5rem;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid rgba(11, 11, 11, 0.1);

  &.clip-border {
    border-bottom: none;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      border-top: 1px solid $color-border-btn-secondary;
      width: calc(100% - 2*1.5rem);
      left: 1.5rem;
      bottom: 0;
    }
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .title {
      font-size: 1.5rem !important;
      line-height: 1.5;
      font-weight: bold;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: $color-text-praise-title;
    }

    .title-reverse {
      order: 2;
    }
  }

  .step-el {
    display: flex;
    align-items: center;
    gap: 10px;

    .step-el-icon-div {
      width: 28px;
      min-width: 28px;
      height: 28px;
      background-color: $color-btn-primary;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      .step-el-icon {
        width: 12px;
      }

      &.step-el-completed {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          background-color: $color-btn-primary;
          width: 34px;
          min-width: 34px;
          height: 34px;
          top: -3px;
          left: -3px;
          opacity: 0.2;
          z-index: -1;
          border-radius: 50%;
        }
      }
    }

    &.step-el-disabled {
      color: $color-text-light;

      .step-el-icon-div {
        background-color: $color-background-secondary;
      }
    }
  }

  .name-el {
    font-weight: 600;
  }
}