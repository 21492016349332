.title {
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: -0.01em;
	text-align: left;
	color: rgba(171, 131, 39, 1);
	margin-bottom: 20px;
}

.subtitle {
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	text-align: left;
	color: rgba(38, 38, 38, 1);
}

.content {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
	color: rgba(38, 38, 38, 1);
	margin-bottom: 30px;
}

.content ul {
	list-style-type: disc;
	margin-left: 15px;
}

.button-wrapper {
	display: flex;
	gap: 10px;

	button {
		width: 48%;
		margin-top: 10px;
	}
}

.input-number {
	border-left: 0px !important;

	:global {
		.ant-input-number-input-wrap .ant-input-number-input {
			text-align: center;
		}

		.ant-input-number-outlined {
			border-left: 0px;
			border-right: 0px;
		}

		.ant-input-number-group-addon {
			background-color: white !important;
			cursor: pointer !important;
			padding: 0px;

			.ant-btn-icon {
				padding: 10px;
			}
		}

		.ant-input-number-group-addon:hover:not([disabled]) {
			background-color: white !important;
		}

		.ant-input-number-outlined.ant-input-number-disabled input[disabled] {
			background-color: white;
			color: black;
		}
	}
}

.wrapper-top {
	padding: 24px 20px 0px 20px;
}

.wrapper-bottom {
	padding: 0px 20px 24px 20px;
}

.wrapper-content {
	padding: 0px 20px 0px 20px;
}

.pointers {
	padding-bottom: 20px;
	list-style: disc;
	margin-left: 18px;
}

.goal-units {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: rgba(38, 38, 38, 1);
}